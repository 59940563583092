import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionAdmissionEnum } from '../../enums/permission.enum';
import { Observable, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { Note } from '../../../dashboard/incidents/incidents.model';
import {
  GetAdmissionsNotesById,
  GetPatientNoteReasons,
  SetAdmissionsNotePage,
  SetAdmissionsNotesById,
  UpdateAdmissionsNotesById,
} from '../../../dashboard/store/admissions/admissions.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotesErrors } from '../../../dashboard/patients-doc-admission/patients-doc-admission.component';
import { Select, Store } from '@ngxs/store';
import { AdmissionsState } from '../../../dashboard/store/admissions/admissions.state';
import { AdmissionType } from '../../../dashboard/admissions/admissions.model';
import { AdmissionTypeEnum } from '../../enums/admission.enum';
import { Reason } from '../../../dashboard/store/patients/patients-list/patients.state';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  @Select(AdmissionsState.notePage) notePage$: Observable<number>;
  @Select(AdmissionsState.reason) reason$: Observable<Reason[]>;

  @Input() selectedAdmission;
  @Input() editableNote: Note = null;

  @Output() cancelNotesEmitter = new EventEmitter();
  @Output() changeFullScreen = new EventEmitter();
  @Output() changeShowSlideIn = new EventEmitter();

  config = {
    suppressScrollX: true,
  };
  slideAnimateDue = 350;

  showSlideIn = true;
  noteForm: FormGroup;
  noteErrors: NotesErrors;

  disabledSave = false;

  protected readonly PermissionAdmissionEnum = PermissionAdmissionEnum;

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.noteForm = this.fb.group({
      reason: [null, Validators.required],
      text: [null, Validators.required],
    });
    this.getNotesById(this.selectedAdmission.id);
    this.getPatientNoteReason(this.selectedAdmission.id, this.selectedAdmission.type);
  }

  newNote(): void {
    this.editableNote = { id: null, text: '' };
    this.changeFullScreen.emit(false);
    this.changeShowSlideIn.emit(false);
    this.showSlideIn = false;
    timer(this.slideAnimateDue)
      .pipe(take(1))
      .subscribe(() => {
        this.showSlideIn = true;
        this.changeShowSlideIn.emit(true);
        this.noteForm.reset();
        this.noteErrors = null;
        this.cd.markForCheck();
      });
  }

  updateNote(note: Note): void {
    this.editableNote = note;
    this.changeFullScreen.emit(false);
    this.changeShowSlideIn.emit(false);
    this.showSlideIn = false;
    timer(this.slideAnimateDue)
      .pipe(take(1))
      .subscribe(() => {
        this.showSlideIn = true;
        this.changeShowSlideIn.emit(true);
        this.noteForm.patchValue({
          reason: note.reason?.id ? note.reason?.id : null,
          text: note.text,
        });
        this.cd.markForCheck();
      });
  }

  saveNotes(): void {
    if (this.editableNote.id) {
      this.updateNotesById(this.selectedAdmission.id, this.editableNote);
    } else {
      this.setAdmissionNotesById(this.selectedAdmission.id);
    }
  }

  setAdmissionNotesById(id: string): void {
    this.disabledSave = true;
    this.store
      .dispatch(new SetAdmissionsNotesById({ ...this.noteForm.value, id }))
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.showSlideIn = false;
          timer(this.slideAnimateDue)
            .pipe(take(1))
            .subscribe((): void => {
              this.showSlideIn = true;
              this.changeShowSlideIn.emit(true);
              this.changeFullScreen.emit(true);
              this.editableNote = null;
              this.disabledSave = false;
              this.setAdmissionNotesPage(1);
              this.getNotesById(id);
              this.cd.markForCheck();
            });
        },
        error: (err): void => {
          this.noteErrors = err.error;
          this.disabledSave = false;
          this.cd.markForCheck();
        },
      });
  }

  updateNotesById(id: string, note: Note): void {
    this.disabledSave = true;
    this.setAdmissionNotesPage(1);
    const payload = {
      admissionId: this.selectedAdmission.id,
      note: {
        ...note,
        ...this.noteForm.value,
        reason: this.noteForm.value.reason,
      },
      id,
    };
    this.store
      .dispatch(new UpdateAdmissionsNotesById(payload))
      .pipe(take(1))
      .subscribe({
        next: (): void => {
          this.showSlideIn = false;
          this.changeShowSlideIn.emit(false);
          timer(this.slideAnimateDue)
            .pipe(take(1))
            .subscribe(() => {
              this.showSlideIn = true;
              this.changeShowSlideIn.emit(true);
              this.changeFullScreen.emit(true);
              this.editableNote = null;
              this.disabledSave = false;
              this.getNotesById(id);
              this.setAdmissionNotesPage(1);
              this.noteForm.reset();
              this.cd.markForCheck();
            });
        },
        error: (err): void => {
          this.noteErrors = err.error;
          this.disabledSave = false;
          this.cd.markForCheck();
        },
      });
  }

  cancelNotes(isBack: boolean = !!this.editableNote): void {
    if (isBack && this.selectedAdmission?.notes?.length) {
      this.showSlideIn = false;
      this.changeShowSlideIn.emit(false);

      timer(this.slideAnimateDue)
        .pipe(take(1))
        .subscribe(() => {
          this.showSlideIn = true;
          this.changeShowSlideIn.emit(true);
          this.changeFullScreen.next(true);
          this.noteErrors = null;
          this.editableNote = null;
          this.setAdmissionNotesPage(1);
          this.noteForm.reset();
          this.cd.markForCheck();
        });
    } else {
      this.showSlideIn = false;
      this.changeShowSlideIn.emit(false);
      this.cancelNotesEmitter.emit(false);
      this.noteErrors = null;
      timer(this.slideAnimateDue)
        .pipe(take(1))
        .subscribe(() => {
          this.editableNote = null;
          this.changeFullScreen.next(false);
          this.noteForm.reset();
          this.cd.markForCheck();
        });
      this.setAdmissionNotesPage(1);
      this.cd.markForCheck();
    }
  }

  getNotesById(id: string, loadNextPage?: boolean): void {
    this.notePage$.pipe(take(1)).subscribe((page: number): void => {
      if (loadNextPage) {
        this.setAdmissionNotesPage(page + 1);
      }
      this.store
        .dispatch(new GetAdmissionsNotesById(id, { perPage: 50, notSavePerPage: true }))
        .pipe(take(1))
        .subscribe(data => {
          this.selectedAdmission.notes = data.admissions.notes;
          this.cd.detectChanges();
        });
    });
  }

  changeNote(): void {
    const value = this.noteForm.value.text?.trim();
    if (!value || value.startsWith(' ')) {
      this.noteForm.controls.text.patchValue(null);
    }
  }

  onScrollDown(): void {
    this.getNotesById(this.selectedAdmission.id, true);
  }

  private setAdmissionNotesPage(page: number = 1): void {
    this.store.dispatch(new SetAdmissionsNotePage(page));
  }

  private getPatientNoteReason(id: string, type: AdmissionType): void {
    const isDischarge: boolean = type.id === AdmissionTypeEnum.DischargeSummary || type.id === AdmissionTypeEnum.DischargeSummaryInt;
    this.store.dispatch(new GetPatientNoteReasons(id, isDischarge));
  }
}
